import dayjs from 'dayjs'
import * as React from 'react'
import Popup from 'reactjs-popup'

import type { TenantData } from 'api/tenants'

import { TimeScale, ShiftBar, ShiftPopover } from 'components/common'
import { getRandomNumber, getShiftBarWidthByDuration, SHIFT_SCHEDULE_TYPE_ID } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './WorkerSchedule.module.scss'

import type { EditShiftsType, WorkPlanSchedulesType } from '../Schedules/types'

type Props = {
  date: string
  editGroups: EditShiftsType[]
  onEditGroupsChange: (items: EditShiftsType[]) => void
  tenantWithDate?: TenantData
}

const WorkerSchedule: React.FC<Props> = ({ date, editGroups, onEditGroupsChange, tenantWithDate }) => {
  const {
    businessStartTime,
    businessDuration,
    getTimesByShiftBarX,
    getShiftBarXbyStartTime,
    getWorkDate,
    getTimeOver24h,
  } = useBusinessTime(tenantWithDate)

  const isPast = React.useMemo(
    () => dayjs(getWorkDate(dayjs().format('YYYY-MM-DD'))).isAfter(date, 'day'),
    [date, getWorkDate]
  )
  const isOpen = (group: string) => editGroups.find(g => g.name === group)?.isOpen

  const handleGroupNameClick = (group: string) => {
    const newEditGroups = editGroups.map(g => (g.name === group ? { ...g, isOpen: !g.isOpen } : g))
    onEditGroupsChange(newEditGroups)
  }

  const onDeleteWorkerScheduleType = (groupName: string, scheduleId: number | null, workerId: number | undefined) => {
    const newEditGroups = editGroups.map(group => {
      if (group.name !== groupName) {
        return group
      }
      const workers = group.workers.map(w => ({
        ...w,
        schedules: w.schedules.filter(s => w.workerId !== workerId || s.scheduleId !== scheduleId),
      }))
      return {
        ...group,
        isOpen: group.isOpen,
        workers,
      }
    })

    onEditGroupsChange(newEditGroups)
  }

  const handleShiftBarAdd = (groupName: string, startPos: number, endPos: number, workerId?: number) => {
    if (!groupName && !workerId) {
      return
    }

    const time = getTimesByShiftBarX(startPos)
    const startAt = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
    const addItem: WorkPlanSchedulesType = {
      scheduleId: getRandomNumber(),
      scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID,
      supportWorkspaceId: null,
      supportWorkspaceName: null,
      startAt,
      duration: (endPos - startPos) * 900,
      editable: true,
    }

    const newEditGroups = editGroups.map(group => {
      if (group.name !== groupName) {
        return group
      }
      return {
        ...group,
        workers: group.workers.map(w => {
          const getSchedules = () => {
            if (w.workerId === workerId) {
              return [...w.schedules, addItem]
            }
            return w.schedules
          }
          return {
            ...w,
            schedules: getSchedules(),
          }
        }),
      }
    })

    onEditGroupsChange(newEditGroups)
  }

  const getSingleWorkerEditGroup = (
    groupName: string,
    workerId: number,
    index: number,
    startAt: string,
    duration: number
  ) => {
    return editGroups.map(group => {
      if (group.name !== groupName) {
        return group
      }

      const workers = group.workers.map(w => {
        if (w.workerId !== workerId) {
          return w
        }
        return {
          ...w,
          schedules: w.schedules
            .filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
            .map((s, idx) => {
              if (idx === index) {
                return { ...s, startAt, duration }
              }
              return s
            }),
        }
      })
      return {
        ...group,
        workers,
      }
    })
  }

  const handleWorkerShiftBarChange = (groupName: string, workerId: number, index: number, x: number, width: number) => {
    const time = getTimesByShiftBarX(x)
    const startAt = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
    const duration = width * 900
    const newEditGroups = getSingleWorkerEditGroup(groupName, workerId, index, startAt, duration)

    onEditGroupsChange(newEditGroups)
  }

  const getShiftBarItems = (groupName: string, schedules: WorkPlanSchedulesType[], workerId?: number) =>
    schedules.map((schedule, index) => {
      const startAt = dayjs(schedule.startAt)
      const endAt = dayjs(startAt).add(schedule.duration, 'second')
      const x = getShiftBarXbyStartTime(schedule.startAt, date)
      const width = getShiftBarWidthByDuration(schedule.duration)
      const id = schedule.scheduleId || getRandomNumber()

      return {
        id: id.toString(),
        content: (
          <ShiftPopover
            label={`勤務時間${index + 1}`}
            time={`${getTimeOver24h(startAt.format('HH:mm'), true)}〜${getTimeOver24h(endAt.format('HH:mm'))}`}
            deleteKey={id.toString()}
            onDelete={
              isPast
                ? undefined
                : () => workerId && onDeleteWorkerScheduleType(groupName, schedule.scheduleId, workerId)
            }
          />
        ),
        x,
        width,
      }
    })

  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr className={styles.timeHeader}>
            <td className={`bg-secondary-pale px-4 ${styles.tableHeader}`}>名前</td>
            <td className="p-0">
              <TimeScale tenantWithDate={tenantWithDate} />
            </td>
          </tr>
        </thead>
        {editGroups.map(({ groupId, name, workers }, index) => (
          <tbody key={`group-${groupId}-${index}`}>
            <tr className={styles.tableRow}>
              <td className={styles.groupContent}>
                <Popup
                  trigger={
                    <div className="d-flex align-items-center px-4" onClick={() => handleGroupNameClick(name)}>
                      <i className={`icf-carot_${isOpen(name) ? 'down' : 'right'} me-1`} />
                      <div className="text-truncate">{name}</div>
                    </div>
                  }
                  position="bottom center"
                  on="hover"
                  arrowStyle={{ color: 'black' }}
                >
                  <span className="text-white bg-black p-1 px-3 rounded font-small">
                    {name}:{workers.length}人
                  </span>
                </Popup>
              </td>
            </tr>
            {isOpen(name) &&
              workers.map((worker, i) => (
                <tr key={`worker-${worker.workerId}-${i}`} className={styles.tableRow}>
                  <td className={`${styles.tableHeader} ${styles.workerName}`}>
                    <div className="d-flex align-items-center">
                      <div className="text-truncate me-auto">{worker.name}</div>
                    </div>
                  </td>

                  <td className={styles.tableContent}>
                    <ShiftBar
                      items={getShiftBarItems(name, worker.schedules, worker.workerId)}
                      businessStartTime={businessStartTime}
                      shiftBarWidth={businessDuration}
                      onAdd={(startPos, endPos) => handleShiftBarAdd(name, startPos, endPos, worker.workerId)}
                      onChange={(idx, x, width) => handleWorkerShiftBarChange(name, worker.workerId, idx, x, width)}
                      disabled={isPast}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        ))}
      </table>
    </div>
  )
}

export default WorkerSchedule
