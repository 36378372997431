import dayjs from 'dayjs'
import { find, isEqual } from 'lodash'
import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col } from 'reactstrap'

import { PlanUpdateModeTypes } from 'api/plans'
import type { UpdatePlanBulk, WorkersPlan } from 'api/plans'

import { getWorkspaceSummary, selectDashboardStatus } from 'slices/dashboardSlice'
import { selectPlansStatus, updatePlanBulkCreate } from 'slices/plansSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import { BadgeLabel, WorksSelector, MultipleFooter, WorkerPopover, SubmitFooter, TimeSelect } from 'components/common'
import type { ColorType, SelectedScheduleType } from 'components/common/types'
import { UNSELECTED_SCHEDULE_TYPE_ID } from 'components/common/utils'

import useAssignment from 'hooks/useAssignment'
import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

import { TeamWorkerCard } from './TeamWorkerCard'
import { TeamWorkerReassign } from './TeamWorkerReassign'
import { getWorkerPerformance } from './utils'

import styles from './TeamAssignment.module.scss'

import type { ScheduleEditType } from './TeamWorkerReassign'

export const TeamAssignment = () => {
  const [focusId, setFocusId] = React.useState<number | undefined>()
  const [selectorOpen, setSelectorOpen] = React.useState(false)
  const [multipleMode, setMultipleMode] = React.useState(false)
  const [selectedWorker, setSelectedWorker] = React.useState<number[]>([])
  const [initEditData, setInitEditData] = React.useState<ScheduleEditType[]>([])
  const [editData, setEditData] = React.useState<ScheduleEditType[]>([])
  const [currentScheduleType, setCurrentScheduleType] = React.useState<SelectedScheduleType | undefined>()
  const [selectedScheduleType, setSelectedScheduleType] = React.useState<SelectedScheduleType | undefined>()
  const [openReassign, setOpenReassign] = React.useState(false)
  const [editChangesDiscardDialogOpen, setEditChangesDiscardDialogOpen] = React.useState(false)

  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    team: { groupId },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { workspaceSummary } = useSelector(selectDashboardStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { plans } = useSelector(selectPlansStatus, shallowEqual)

  const { startTime, openDayjs, workspaceId, setSubmitted, getSubmitWorkerPlanFromWorker, initTeamSchedules } =
    useAssignment()
  const { planWorkDate, planLastUpdatedAt, planLastUpdater } = usePlans()

  const { timeRange, getHourOver24h, getWorkDate } = useBusinessTime()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    dispatch(getWorkspaceSummary(workspaceId, getWorkDate(dayjs().format('YYYY-MM-DD'))))
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId, getWorkDate])

  React.useEffect(() => {
    setInitEditData(initTeamSchedules)
    setEditData(initTeamSchedules)
  }, [initTeamSchedules])

  const handleCancel = () => {
    setEditData(initEditData)
    setEditChangesDiscardDialogOpen(false)
  }

  const handleSubmit = () => {
    if (!plans || !workspaceId) {
      return
    }

    setSubmitted(true)
    const compare = initEditData.flatMap(s => s.workers)
    const data: WorkersPlan[] = editData
      .filter(edit => edit.scheduleTypeId !== UNSELECTED_SCHEDULE_TYPE_ID && edit.name !== '予定未入力')
      .flatMap(edit => {
        return edit.workers
          .filter(worker => !isEqual(find(compare, ['workerId', worker.workerId]), worker))
          .flatMap(worker => getSubmitWorkerPlanFromWorker(worker, edit.scheduleTypeId, !edit?.color))
      })

    const updatePlanDate: UpdatePlanBulk = {
      updateMode: PlanUpdateModeTypes.WorkPlan,
      workersPlan: data,
    }
    dispatch(updatePlanBulkCreate(workspaceId, planWorkDate, updatePlanDate))
  }

  const handlePopoverOpen = (workerId: number, scheduleTypeId: number, name: string) => {
    setFocusId(workerId)
    setSelectedWorker([workerId])
    setCurrentScheduleType({ id: scheduleTypeId, name })
  }

  const handlePopoverClose = () => {
    setFocusId(undefined)
  }

  const initialSelected = () => {
    setSelectorOpen(false)
    setMultipleMode(false)
    setSelectedWorker([])
    setCurrentScheduleType(undefined)
  }

  const handleSelectorWorkClick = (scheduleTypeId: number, name: string, color?: ColorType) => {
    setSelectedScheduleType({ id: scheduleTypeId, name, color: color })
    setOpenReassign(true)
  }

  const handleWorkerCardClick = (workerId: number, scheduleTypeId: number, name: string) => {
    const sameScheduleType = currentScheduleType?.id === scheduleTypeId
    if (sameScheduleType && selectedWorker.includes(workerId)) {
      setSelectedWorker(selectedWorker.filter(id => id !== workerId))
    } else if (sameScheduleType) {
      setSelectedWorker([...selectedWorker, workerId])
    } else {
      setCurrentScheduleType({ id: scheduleTypeId, name })
      setSelectedWorker([workerId])
    }
  }

  const handleReassignClose = () => {
    initialSelected()
    setOpenReassign(false)
  }
  const handleMultipleButtonClick = () => {
    setSelectedWorker([])
    setMultipleMode(!multipleMode)
  }

  const unchanged = React.useMemo(() => isEqual(initEditData, editData), [editData, initEditData])

  const sortSchedule = (a: ScheduleEditType, b: ScheduleEditType) => {
    // workersの数でソートする
    const aHasWorkers = a.workers.length > 0
    const bHasWorkers = b.workers.length > 0
    // 予定未入力にworkersがいる場合は必ず先頭に来るようにする
    if (a.name === '予定未入力' && aHasWorkers) {
      return -1
    }
    if (b.name === '予定未入力' && bHasWorkers) {
      return 1
    }

    // workersがいる場合は先、いない場合は後に来るようにする
    if (aHasWorkers && !bHasWorkers) {
      return -1
    }
    if (!aHasWorkers && bHasWorkers) {
      return 1
    }

    return 0
  }

  const sortedData = useMemo(() => editData.sort(sortSchedule), [editData])

  return (
    <>
      <div className={styles.container}>
        <div className="d-flex align-items-center">
          <div className="font-x-large fw-bold flex-grow-1">人員配置</div>
          <div className="bg-white rounded">
            <Button outline onClick={() => navigate(`/team-schedules/${getWorkDate(dayjs().format('YYYY-MM-DD'))}`)}>
              本日の作業計画
            </Button>
          </div>
          <div className="bg-white rounded ms-3">
            <Button
              outline
              className="d-flex align-items-center"
              disabled={multipleMode}
              onClick={handleMultipleButtonClick}
            >
              <i className="icf-edit pe-2 font-large" />
              <div className="ps-1">まとめて配置変更</div>
            </Button>
          </div>
        </div>
        <div className="d-flex align-items-center pt-3">
          <div className="pe-2">表示中の配置:</div>
          <TimeSelect
            hour={getHourOver24h(startTime)}
            minute={startTime.split(':')[1]}
            label=""
            onChange={(hour, minute) => {
              navigate(`?hour=${hour}&minute=${minute}`, { replace: true })
            }}
            range={timeRange.start}
          />
          <div className="pe-2">からの配置</div>
          <Button color="link" className="text-decoration-none" onClick={() => navigate(pathname, { replace: true })}>
            <div>現在時刻の配置にする</div>
          </Button>
        </div>
        {sortedData.map((type, index) => (
          <Card key={`card-${type.scheduleTypeId}-${index}`} className="my-3 shadow-sm">
            <CardBody className="p-3">
              <div className="d-flex mb-2">
                <BadgeLabel label={type.name} color={type.color} />
              </div>
              <Row sm={4} className="mx-0">
                {type.workers.map((worker, i) => (
                  <Col key={`col-${worker.workerId}`} className={`pt-2 pe-0 ps-${i % 4 !== 0 ? '2' : '0'}`}>
                    {multipleMode ? (
                      <TeamWorkerCard
                        workerId={worker.workerId}
                        workerName={worker.workerName}
                        groupName={worker.groupName}
                        groupColor={worker.groupColor}
                        status={selectedWorker.includes(worker.workerId) ? 'selected' : worker.status}
                        performance={getWorkerPerformance(workspaceSummary, groupId, worker.workerId, openDayjs)}
                        onClick={() => handleWorkerCardClick(worker.workerId, type.scheduleTypeId, type.name)}
                      />
                    ) : (
                      <WorkerPopover
                        startAt={worker.startAt}
                        duration={worker.duration}
                        scheduleType={{
                          id: type.scheduleTypeId,
                          name: type.name,
                          color: type?.color,
                        }}
                        offsetY={-45}
                        onClick={() => setSelectorOpen(true)}
                        onOpen={() => handlePopoverOpen(worker.workerId, type.scheduleTypeId, type.name)}
                        onClose={handlePopoverClose}
                      >
                        <TeamWorkerCard
                          workerId={worker.workerId}
                          workerName={worker.workerName}
                          groupName={worker.groupName}
                          groupColor={worker.groupColor}
                          status={worker.status}
                          isFocus={focusId === worker.workerId}
                          performance={getWorkerPerformance(workspaceSummary, groupId, worker.workerId, openDayjs)}
                        />
                      </WorkerPopover>
                    )}
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        ))}
      </div>
      <TeamWorkerReassign
        isOpen={openReassign}
        selectedWorkerIds={selectedWorker}
        selectedScheduleType={selectedScheduleType}
        currentScheduleType={currentScheduleType}
        editData={editData}
        currentTime={openDayjs.format('HH:mm')}
        setEditData={setEditData}
        onClose={handleReassignClose}
        getWorkerPerformance={workerId => getWorkerPerformance(workspaceSummary, groupId, workerId, openDayjs)}
      />
      <WorksSelector
        open={selectorOpen}
        scheduleTypes={partialScheduleTypes}
        workspaces={partialWorkspaces}
        currentScheduleType={currentScheduleType}
        workspaceId={workspaceId ?? 0}
        showWorkspace={true}
        onClose={() => setSelectorOpen(!selectorOpen)}
        onWorkClick={handleSelectorWorkClick}
      />
      {multipleMode && (
        <MultipleFooter
          stepNumber={selectorOpen ? 2 : 1}
          stepText={selectorOpen ? '作業内容を選択' : 'スケジュールを変更したいユーザーを選択'}
          disabled={selectedWorker.length === 0}
          selectorOpen={selectorOpen}
          decisionButtonClick={() => setSelectorOpen(true)}
          onCancel={initialSelected}
        />
      )}
      {!multipleMode && !unchanged && (
        <SubmitFooter
          onCancel={() => setEditChangesDiscardDialogOpen(true)}
          onSubmit={handleSubmit}
          updatedBy={planLastUpdater}
          updatedAt={planLastUpdatedAt}
        />
      )}

      <EditChangesDiscardDialog
        isOpen={editChangesDiscardDialogOpen}
        onCancel={() => setEditChangesDiscardDialogOpen(false)}
        onDiscard={handleCancel}
      />
    </>
  )
}
