import dayjs from 'dayjs'
import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ModalBody, ModalFooter, Button, CardBody, CardText, CardLink } from 'reactstrap'

import { getTemplateData, getTemplateList, selectTemplateStatus } from 'slices/templateSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import type { EditGroupsType, WorkPlanSchedulesType } from 'components/Schedules/types'
import { Modal, SelectBoxFormat } from 'components/common'
import { getRandomNumber, SHIFT_SCHEDULE_TYPE_ID, ColumnSizes, SUPPORT_SCHEDULE_TYPE_ID } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

import styles from './ImportTemplateDialog.module.scss'

type Props = {
  isOpen: boolean
  workspaceId: number
  editGroups: EditGroupsType[]
  workerIds: number[]
  setEditGroups: (items: EditGroupsType[]) => void
  onSuccess: () => void
  onCancel: () => void
}

const TEMPLATE_IDS_NUM = 96

const convertWorkSchedulesToTemplateSchedule = (schedules: WorkPlanSchedulesType[], isShift: boolean) =>
  _.chain(schedules)
    .filter(s => isShift || s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
    .sortBy(o => dayjs(o.startAt).unix())
    .reverse() // 作業が重なっている場合､開始時間が早い作業を優先する(遅い時間から処理する)
    .reduce((acc: (WorkPlanSchedulesType | null)[], cur) => {
      const startAt = dayjs(cur.startAt)
      const startIndex = (startAt.local().unix() - startAt.startOf('day').unix()) / 900
      const endIndex = cur.duration / 900 + startIndex

      if (endIndex <= TEMPLATE_IDS_NUM) {
        return _.fill(acc, cur, startIndex, endIndex)
      }

      // endIndexの値が96(24:00)を超えてしまう場合
      // 24:00を超える分はindex0からのデータにする
      const before24h = _.fill(acc, cur, startIndex, TEMPLATE_IDS_NUM)
      const after24h = _.fill(acc, cur, 0, endIndex - TEMPLATE_IDS_NUM)
      return after24h.concat(before24h)
    }, Array(96).fill(null))
    .valueOf()

const ImportTemplateDialog: React.FC<Props> = props => {
  const { isOpen, workspaceId, workerIds, editGroups, setEditGroups, onCancel, onSuccess } = props
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<number>()
  const [submitted, setSubmitted] = React.useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { templateList, template, isRequesting } = useSelector(selectTemplateStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const { businessStartIndex } = useBusinessTime()
  const { planWorkDate } = usePlans()

  React.useEffect(() => {
    isOpen && dispatch(getTemplateList(workspaceId))
  }, [dispatch, workspaceId, isOpen])

  React.useEffect(() => {
    setSelectedTemplateId(undefined)
  }, [isOpen])

  const items = React.useMemo(
    () =>
      templateList.map(t => ({
        key: t.id,
        value: t.name,
      })) || [],
    [templateList]
  )

  const isEmpty = React.useMemo(() => _.isEmpty(templateList), [templateList])

  const mergeSchedules = React.useCallback(
    (
      workerSchedules: WorkPlanSchedulesType[],
      templateSchedules: ({ id: number; isSupport: boolean } | null)[]
    ): WorkPlanSchedulesType[] => {
      const shiftSchedules = workerSchedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
      if (_.isEmpty(shiftSchedules)) {
        return workerSchedules
      }

      const convertedWorkShiftSchedules = convertWorkSchedulesToTemplateSchedule(shiftSchedules, true)
      const targetDate = dayjs(planWorkDate)
      const convertedWorkSchedules = convertWorkSchedulesToTemplateSchedule(workerSchedules, false)
      // テンプレートスケジュールとワーカースケジュールを96の配列にマージする
      const mergedWorkSchedules = convertedWorkSchedules.map((schedule, index) => {
        if (schedule !== null) {
          return schedule
        }
        const target = templateSchedules[index]
        if (convertedWorkShiftSchedules[index] === null || target === null) {
          return null
        }

        return {
          scheduleId: getRandomNumber(),
          scheduleTypeId: target.isSupport ? SUPPORT_SCHEDULE_TYPE_ID : target.id,
          supportWorkspaceId: target.isSupport ? target.id : null,
          supportWorkspaceName: target.isSupport ? partialWorkspaces.find(w => w.id === target.id)?.name || '' : null,
          startAt: '',
          duration: 0,
          editable: true,
        }
      })

      // scheduleの配列をbusinessStartIndexの位置で分割して順序を入れ替える
      const beforeStartIndexSchedules = mergedWorkSchedules.slice(0, businessStartIndex)
      const afterStartIndexSchedules = mergedWorkSchedules.slice(businessStartIndex)
      const orderChangedWorkSchedules = afterStartIndexSchedules.concat(beforeStartIndexSchedules)

      const workPlanSchedules = orderChangedWorkSchedules
        // マージされたスケジュールをWorkPlanSchedulesTypeに変換する
        .reduce<(WorkPlanSchedulesType | null)[]>((acc, cur, index) => {
          const prev = _.last(acc)

          if (cur === null) {
            return acc.concat(null)
          }
          if (!prev || prev.scheduleTypeId !== cur.scheduleTypeId) {
            return acc.concat({
              ...cur,
              duration: 900,
              startAt: targetDate
                .clone()
                .add((index + businessStartIndex) * 900, 's')
                .format(),
            })
          }
          acc.splice(acc.length - 1, 1, { ...prev, duration: prev.duration + 900 })
          return acc
        }, [])
        .filter(wp => wp !== null) as WorkPlanSchedulesType[]

      return workPlanSchedules.concat(shiftSchedules)
    },
    [businessStartIndex, partialWorkspaces, planWorkDate]
  )

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    const newEditGroups = editGroups.map<EditGroupsType>(edit => {
      const workers = edit.workers.map(worker => {
        if (!workerIds.includes(worker.workerId) || !template) {
          return worker
        }
        const schedules = mergeSchedules(worker.schedules, template.ids)
        return { ...worker, schedules }
      })
      return { ...edit, workers }
    })

    setEditGroups(newEditGroups)
    onSuccess()
    setSubmitted(false)
  }, [
    template,
    submitted,
    isRequesting,
    onSuccess,
    setEditGroups,
    editGroups,
    workerIds,
    mergeSchedules,
    businessStartIndex,
  ])

  return (
    <Modal isOpen={isOpen}>
      <ModalBody className="font-large fw-bold">予定テンプレートを入力</ModalBody>
      <ModalBody className="font-small">
        <CardBody className="mb-3">
          <CardText>
            選択されているメンバーに登録されている予定テンプレートを入力します。既に入力されている予定がある場合は､既に入力されている予定が優先されます｡
          </CardText>
        </CardBody>
        <CardBody className="py-0 mt-3">
          <SelectBoxFormat
            label="予定テンプレート"
            value={selectedTemplateId?.toString()}
            size={ColumnSizes.middle}
            items={items}
            onChange={event => {
              setSelectedTemplateId(Number(event.key))
              dispatch(getTemplateData(workspaceId, event.key as number))
            }}
            disabled={isEmpty}
          />
        </CardBody>
        {isEmpty && (
          <div>
            <CardBody>
              <CardText className="text-danger">予定テンプレートが登録されていません｡</CardText>
              <CardText>
                予定テンプレートはワークスペース管理&gt;該当ワークスペース&gt;
                <CardLink className={styles.link} onClick={() => navigate(`/workspaces/${workspaceId}/templates`)}>
                  予定テンプレート管理
                </CardLink>
                から登録できます｡
              </CardText>
            </CardBody>
          </div>
        )}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button outline onClick={onCancel}>
          キャンセル
        </Button>
        <Button color="primary" className="px-4" onClick={() => setSubmitted(true)} disabled={isEmpty}>
          予定テンプレートを入力
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ImportTemplateDialog
