import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'

import type { WorkspaceSummaryData } from 'api/dashboard'

import { NO_GROUP_ID } from 'components/TeamSelect/TeamSelectWorkers'
import { TimeScale, WorkerIcon } from 'components/common'
import TableScale from 'components/common/AssignedNumberTable/TableScale'
import type { TimeReducerType, ColorType } from 'components/common/types'
import { isSupportedWorkerGroup, timeDataReducer } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './TeamProgressTable.module.scss'

export type Props = {
  groupId: number
  groupName: string
  groupColor: ColorType
  workspaceSummaryData: WorkspaceSummaryData
}

export const TeamProgressTable = ({ groupId, groupName, groupColor, workspaceSummaryData }: Props) => {
  const [openGroup, setOpenGroup] = useState(true)

  const { businessHourBlocks, getHourOver24h } = useBusinessTime()

  const workerDataList = useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }

    return workspaceSummaryData.groups
      .filter(
        group =>
          (groupId === NO_GROUP_ID ? group.groupId === null : group.groupId === groupId) &&
          !isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName)
      )
      .flatMap(group =>
        group.workers.map(worker => {
          // worker内のスケジュール毎のデータを整形して1つの配列にまとめる
          const timeDataList = worker.hourlyWorkerData.flatMap(hourlyData =>
            hourlyData.data.map(d => {
              const dayObject = dayjs(d.time)
              const hour = getHourOver24h(dayObject.format('HH'))
              const minute = dayObject.format('mm')
              return {
                time: `${hour}:${minute}`,
                planCount: d.planCount,
                recordCount: d.recordCount,
                rate: null,
              }
            })
          )

          // 同じtimeのデータを集計
          const workerData: { [key: string]: TimeReducerType } = timeDataList.reduce(timeDataReducer, {})

          return {
            workerId: worker.workerId,
            workerName: worker.workerName,
            workerData,
          }
        })
      )
  }, [workspaceSummaryData, groupId, getHourOver24h])

  const groupData: { [key: string]: TimeReducerType } = useMemo(
    () => workerDataList.flatMap(d => Object.values(d.workerData)).reduce(timeDataReducer, {}),
    [workerDataList]
  )
  const groupCounts = useMemo(
    () =>
      businessHourBlocks.reduce((acc, cur) => {
        const rate = groupData[`${cur}:00`]?.rate || null
        const warning = rate !== null && Math.abs(100 - rate) >= 10
        acc.set(cur, <div className={warning ? 'text-danger' : ''}>{rate ? `${rate}%` : ''}</div>)
        return acc
      }, new Map()),
    [businessHourBlocks, groupData]
  )
  const workerCounts = useCallback(
    (workerData: { [key: string]: TimeReducerType }) =>
      businessHourBlocks.reduce((acc, cur) => {
        const rate = workerData[`${cur}:00`]?.rate || null
        const warning = rate !== null && Math.abs(100 - rate) >= 10
        acc.set(cur, <div className={warning ? 'text-danger' : ''}>{rate ? `${rate}%` : ''}</div>)
        return acc
      }, new Map()),
    [businessHourBlocks]
  )

  return (
    <table>
      <thead>
        <tr className={styles.header}>
          <th className={styles.nameHeader}>名前</th>
          <TimeScale round />
        </tr>
      </thead>

      <tbody>
        <tr className={styles.groupRow}>
          <td className={styles.groupLabel}>
            <div className="ps-2 d-flex align-items-center" role="button" onClick={() => setOpenGroup(!openGroup)}>
              <i className={`icf-carot_${openGroup ? 'down' : 'right'} me-1`} />
              <div className="text-truncate">{groupName}</div>
            </div>
          </td>

          <TableScale items={groupCounts} />
        </tr>

        {openGroup &&
          workerDataList.map(worker => (
            <tr key={worker.workerId} className={styles.workerRow}>
              <td className={styles.workerLabel}>
                <div className="px-4 py-2 d-flex align-items-center">
                  <WorkerIcon name={worker.workerName} groupColor={groupColor} />
                  <div className="ms-2 text-truncate fw-normal">{worker.workerName}</div>
                </div>
              </td>
              <td>
                <TableScale items={workerCounts(worker.workerData)} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}
