import dayjs from 'dayjs'
import * as React from 'react'
import { Card, DropdownItem } from 'reactstrap'

import { DropdownList } from 'components/common'
import type { ColorType } from 'components/common/types'

import usePlans from 'hooks/usePlans'

import styles from './WorkerCard.module.scss'

export type Worker = {
  workerId: string // react-beautiful-dnd で利用するにはstringが都合が良い
  name: string
  wmsMemberId: string
  scheduleId: number | null
  startAt: string
  duration: number
  groupName?: string
  groupColor?: ColorType
  saved?: boolean
  skillIds?: number[]
  supporter?: boolean
  visible?: boolean
}

export type DropdownScheduleType = {
  type: string
  label: string
  color?: ColorType
  skillIds?: number[]
}

type DropdownMenuType = {
  schedules: DropdownScheduleType[]
  otherWorkspaces: DropdownScheduleType[]
}

type Props = {
  worker: Worker
  selected: string[]
  dropdownMenu?: DropdownMenuType
  disabled?: boolean
  draggingId?: string | undefined
  onDropDownMenuClick?: (schedule: DropdownScheduleType) => void
  onClick?: (event: React.MouseEvent, id: string) => void
}

const WorkerCard: React.FC<Props> = props => {
  const {
    worker: { workerId, name, groupName, groupColor, saved, startAt, duration, supporter },
    selected,
    disabled = false,
    draggingId,
    dropdownMenu,
    onDropDownMenuClick = () => {},
    onClick = () => {},
  } = props

  const { planWorkDate } = usePlans()

  const [dropdown1, setDropdown1] = React.useState(false)
  const [dropdown2, setDropdown2] = React.useState(false)

  React.useEffect(() => {
    setDropdown1(!draggingId && selected.length === 1 && selected.includes(workerId))
  }, [draggingId, selected, workerId])

  const active = React.useMemo(() => !draggingId && selected.includes(workerId), [draggingId, workerId, selected])
  const isDraggingOther = React.useMemo(
    () => !!draggingId && selected.includes(workerId) && workerId !== draggingId,
    [workerId, selected, draggingId]
  )
  const isMultiCurrent = React.useMemo(
    () => selected.length > 1 && workerId === draggingId,
    [selected, draggingId, workerId]
  )

  const schedules = React.useMemo(() => dropdownMenu?.schedules || [], [dropdownMenu])
  const otherWorkspaces = React.useMemo(() => dropdownMenu?.otherWorkspaces || [], [dropdownMenu])

  const dropdownToggle1 = () => {
    return active && !disabled && <i className="icf-others font-large" />
  }
  const dropdownToggle2 = (
    <DropdownItem onClick={() => null} className="font-x-small px-1 d-flex justify-content-between">
      <i className="icf-Move" />
      <span className="flex-grow-1 ps-1">配置変更</span>
      <i className="icf-carot_right" />
    </DropdownItem>
  )

  const bgColor = React.useMemo(() => {
    if (active) {
      return 'primary'
    }
    if (supporter) {
      return 'primary-dark'
    }
    return groupColor || 'light-gray'
  }, [active, groupColor, supporter])

  const borderColor = React.useMemo(() => {
    if (isDraggingOther || active || saved) {
      return 'var(--bs-primary)'
    }
    if (supporter) {
      return 'var(--bs-primary-dark)'
    }
    if (!groupColor) {
      return 'var(--bs-light-gray)'
    }
    return `var(--bs-${groupColor})`
  }, [active, isDraggingOther, saved, groupColor, supporter])
  const workerNameStyle = React.useMemo(
    () => (active ? `text-white ${bgColor}` : 'text-black bg-light'),
    [active, bgColor]
  )

  const displayOperationStatus = React.useMemo(() => {
    if (saved) {
      return '変更未保存'
    }
    const start = dayjs(startAt).local()
    const end = start.add(duration, 'seconds')

    // planWorkDateと日付が異なる場合は、24を加算する
    const startFormatted =
      planWorkDate === start.format('YYYY-MM-DD') ? start.format('HH:mm') : `${start.hour() + 24}:${start.format('mm')}`
    const endFormatted =
      planWorkDate === end.format('YYYY-MM-DD') ? end.format('HH:mm') : `${end.hour() + 24}:${end.format('mm')}`

    return `${startFormatted} - ${endFormatted}`
  }, [saved, startAt, duration, planWorkDate])

  const onClickHandler = (event: React.MouseEvent) => {
    if (disabled || !dropdownMenu) {
      return
    }
    onClick(event, workerId)
  }
  return (
    <>
      {isMultiCurrent && <Card className={`position-absolute ${styles.multiCard}`}></Card>}
      <Card
        color={bgColor}
        onClick={onClickHandler}
        className={`${styles.card} ${active && 'text-white'} ${isMultiCurrent && 'position-absolute'}`}
        style={{ borderColor, borderWidth: '2px' }} // classやscssに記載してもborderが優先されてしまうためstyleで指定しました
      >
        {!isDraggingOther && (
          <div className="p-1">
            <div className={`fw-light text-truncate text-white`}>{groupName}</div>
            <div className="d-flex align-items-center">
              <div className={`p-1 text-truncate flex-grow-1 rounded font-middle ${workerNameStyle}`}>{name}</div>
              <DropdownList
                open={dropdown1}
                setOpen={() => setDropdown1(!dropdown1)}
                content={dropdownToggle1()}
                direction="down"
              >
                <DropdownList
                  open={dropdown2}
                  setOpen={() => setDropdown2(!dropdown2)}
                  content={dropdownToggle2}
                  direction="end"
                  dropdownClassName={styles.transform2}
                >
                  {schedules.map(schedule => (
                    <DropdownItem
                      key={schedule.type}
                      className={`d-flex align-items-center font-x-small px-2 ${styles.dropdownItems}`}
                      onClick={() => onDropDownMenuClick(schedule)}
                    >
                      <div>
                        <div className={`${styles.square} bg-${schedule.color} me-1`}></div>
                      </div>
                      <span className="text-truncate">{schedule.label}</span>
                    </DropdownItem>
                  ))}
                  {schedules.length > 0 && otherWorkspaces.length > 0 && <DropdownItem divider />}
                  {otherWorkspaces.map(workspace => (
                    <DropdownItem
                      key={workspace.type}
                      className={`d-flex font-x-small px-2 ${styles.dropdownItems}`}
                      onClick={() => onDropDownMenuClick(workspace)}
                    >
                      <span className="text-truncate">{workspace.label}</span>
                    </DropdownItem>
                  ))}
                </DropdownList>
              </DropdownList>
            </div>
            <div>
              <div className={`fw-light text-white`}>{displayOperationStatus}</div>
            </div>
          </div>
        )}
      </Card>
    </>
  )
}

export default WorkerCard
