import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'

import { selectTenantsStatus } from 'slices/tenantsSlice'

import useBusinessTime from 'hooks/useBusinessTime'

import indicator from './images/indicator.svg'

import styles from './IndicatorProgressBar.module.scss'

export type Props = {
  achievementRate: number
  planRate: number
  warning: boolean
  unit?: string
  planCount?: number | null
}

const IndicatorProgressBar: React.FC<Props> = ({ achievementRate, planRate, warning, unit = '', planCount = 0 }) => {
  const currentPlanCount = planCount ? Math.floor(planCount).toLocaleString() : 0
  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)
  const { flooredCurrentTime } = useBusinessTime(tenantWithDate)

  return (
    <div className={styles.barContainer}>
      <div className={styles.barBg} />
      <div
        className={`${styles.barRecord} ${warning ? styles.barRecordWarning : ''}`}
        style={{ width: `${achievementRate >= 100 ? 100 : achievementRate}%` }}
      />
      <div className={styles.barPlanLine} style={{ width: `${planRate >= 100 ? 100 : planRate}%` }}>
        {process.env.REACT_APP_TEAM_MODE === 'true' ? (
          <img src={indicator} alt="" className={styles.barIndicator} />
        ) : (
          <Popup
            trigger={<img src={indicator} alt="" className={styles.barIndicator} />}
            position="bottom center"
            on="hover"
            arrowStyle={{ color: 'black' }}
          >
            <div className="text-white bg-black p-1 px-3 rounded font-small text-center text-nowrap">
              <div>{`${flooredCurrentTime} 時点`}</div>
              <div>{`${currentPlanCount} ${unit}`}</div>
            </div>
          </Popup>
        )}
      </div>
    </div>
  )
}

export default IndicatorProgressBar
