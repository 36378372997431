import dayjs from 'dayjs'
import { useCallback } from 'react'

import useBusinessTime from './useBusinessTime'

import type { TooltipFormatterCallbackFunction } from 'highcharts'

export const useChart = () => {
  const { getTimeOver24h } = useBusinessTime()

  const tooltipFormatterWithNextTime: TooltipFormatterCallbackFunction = useCallback(
    function () {
      const timeString = getTimeOver24h(
        dayjs(this.x).format('HH:mm'),
        this.point.index === this.series.options.custom!.firstDataIndex
      )
      const splittedTime = timeString.split(':')
      const nextTimeString = `${(Number(splittedTime[0]) + 1).toString().padStart(2)}:${splittedTime[1]}`
      return (
        '<div style="text-align:center">' +
        [
          `${timeString}~${nextTimeString}`,
          this.series.name,
          `${Math.floor(this.y || 0)}${this.series.options.custom!.unit}`,
        ].join('<br>') +
        '</div>'
      )
    },
    [getTimeOver24h]
  )
  const tooltipFormatter: TooltipFormatterCallbackFunction = useCallback(
    function () {
      const timeString = getTimeOver24h(
        dayjs(this.x).format('HH:mm'),
        this.point.index === this.series.options.custom!.firstDataIndex
      )
      return (
        '<div style="text-align:center">' +
        [timeString, this.series.name, `${Math.floor(this.y || 0)}${this.series.options.custom!.unit}`].join('<br>') +
        '</div>'
      )
    },
    [getTimeOver24h]
  )
  return { tooltipFormatter, tooltipFormatterWithNextTime }
}
