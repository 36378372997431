import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { CardBody } from 'reactstrap'

import type { ColorType } from 'components/common/types'

import styles from './TeamWorkerCard.module.scss'
import './icons/_icons.scss'

export const WorkerCardStatuses = {
  Changed: 'changed',
  Selected: 'selected',
  Disabled: 'disabled',
  Null: null,
} as const
type WorkerCardStatusType = (typeof WorkerCardStatuses)[keyof typeof WorkerCardStatuses]
export type WorkerCard = {
  workerId: number
  workerName: string
  groupName: string
  groupColor: ColorType
  wmsMemberId?: string
  status?: WorkerCardStatusType
  disabled?: boolean
}

type Props = WorkerCard & {
  performance: 'bad' | 'normal' | 'good'
  isFocus?: boolean
  onClick?: () => void
}

export const TeamWorkerCard = (props: Props) => {
  const { workerId, workerName, groupName, groupColor, status, isFocus, disabled, performance, onClick } = props
  const navigate = useNavigate()

  const fontColor = status === 'selected' ? 'text-white' : 'text-secondary'
  const isChanged = status === 'changed'
  const cardStyle = React.useMemo(() => {
    if (disabled) {
      return styles.disabled
    }
    if (isFocus) {
      return styles.focus
    }
    if (status) {
      return styles[status]
    }
    return styles.default
  }, [disabled, isFocus, status])

  const onIconClick = () => navigate(`/team-worker/${workerId}`)

  return (
    <div className={`px-1 ${cardStyle}`} onClick={onClick}>
      <CardBody className="d-flex align-items-center px-2 py-0">
        <div className={`${groupColor}-${performance}`}></div>
        <div className="p-2 text-truncate flex-grow-1">
          {workerName}
          <div className="font-x-small text-truncate">
            {isChanged ? (
              <span className="text-primary">変更未保存</span>
            ) : (
              <span className={fontColor}>{groupName}</span>
            )}
          </div>
        </div>
        <div className={fontColor}>{!disabled && <i className="icf-info font-x-large" onClick={onIconClick} />}</div>
      </CardBody>
    </div>
  )
}
