import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Input, Button, Label } from 'reactstrap'

import type { ExportDataType } from 'api/reports'
import { ExportDataTypes } from 'api/reports'

import { showError } from 'slices/notificationSlice'
import { getExportDataUrl, resetExportDataUrl, selectReportsStatus } from 'slices/reportsSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal, SingleDateRangePicker, ItemEdit } from 'components/common'
import SelectBox from 'components/common/FormFormat/SelectBox'
import type { SuggestionItem } from 'components/common/types'
import { downloadByURL } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

type Props = {
  open: boolean
  setOpen: (prop: boolean) => void
  currentWorkspaceId: number | undefined
}

type ExportDataItemType = { key: ExportDataType; value: string }

const DATE_PICKER_MAX_DAYS = 31

const exportDataItem: ExportDataItemType[] = [
  { key: ExportDataTypes.PlanAndRecord, value: '作業計画 + 実績' },
  { key: ExportDataTypes.PlanDetail, value: '作業計画 (詳細)' },
]

const onDetailClick = () => window.open('https://help.smileboard.jp/export-to-csv', '_blank')

const CsvExportDialog = ({ open, setOpen, currentWorkspaceId }: Props) => {
  const [includeProductivityValue, setIncludeProductivityValue] = useState(true)
  const [includeRecord, setIncludeRecord] = useState<boolean | undefined>(undefined)
  const [openRangeDatePicker, setOpenRangeDatePicker] = useState(false)
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<SuggestionItem[]>([])
  const [period, setPeriod] = useState<{ start: Date; end: Date }>()
  const [exportDataType, setExportDataType] = useState(exportDataItem[0])
  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { downloadUrl } = useSelector(selectReportsStatus, shallowEqual)
  const { getWorkDate } = useBusinessTime()

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  // ダイアログを開くたびに、設定を初期化する
  useEffect(() => {
    if (!currentWorkspaceId) {
      return
    }
    const target = partialWorkspaces.find(workspace => workspace.id === currentWorkspaceId)
    if (target) {
      setSelectedWorkspaces([{ id: currentWorkspaceId, value: target.name }])
    }
  }, [currentWorkspaceId, partialWorkspaces])

  const handleApprove = () => {
    if (!period || selectedWorkspaces.length === 0) {
      return
    }
    const targetWorkspaces: number[] = selectedWorkspaces.map(w => (typeof w.id === 'number' ? w.id : Number(w.id)))
    dispatch(
      getExportDataUrl({
        startDate: dayjs(period.start).format('YYYY-MM-DD'),
        endDate: dayjs(period.end).format('YYYY-MM-DD'),
        targetWorkspaces,
        includeProductivityValue,
        includeRecord,
        exportDataType: exportDataType.key,
      })
    )
  }

  const downloadCsv = useCallback(
    async (url: string, filename: string) => {
      try {
        await downloadByURL(url, filename)
      } catch {
        dispatch(showError())
      } finally {
        setOpen(false)
        dispatch(resetExportDataUrl())
      }
    },
    [setOpen, dispatch]
  )

  useEffect(() => {
    if (!downloadUrl) {
      return
    }

    const filename = `archive-${dayjs().format('YYYY-MM-DD-HHmm')}.csv`
    downloadCsv(downloadUrl, filename)
  }, [downloadUrl, downloadCsv])

  const disabled = useMemo(() => !period || selectedWorkspaces.length === 0, [period, selectedWorkspaces])

  const items = useMemo(() => partialWorkspaces.map(w => ({ id: w.id, value: w.name })), [partialWorkspaces])

  const handleExportDataType = (item: ExportDataItemType) => {
    setIncludeRecord(item.key === ExportDataTypes.PlanDetail ? false : undefined)
    setExportDataType(item)
  }

  return (
    <div>
      <CustomModal
        isOpen={open}
        title="CSVエクスポート"
        onCancel={() => setOpen(false)}
        onHideNotification={() => {}}
        approveLabel="CSVエクスポート"
        onApprove={handleApprove}
        approveDisabled={disabled}
        submitName="csv-export-dialog-submit"
      >
        <div className="ms-2">
          <div className="mb-4">
            <div className="my-2 d-flex justify-content-between align-items-center">
              <div className="fw-bold">エクスポートデータ選択</div>
              <Button color="link" className="text-decoration-none" onClick={onDetailClick}>
                エクスポートデータについて
              </Button>
            </div>
            <SelectBox
              id="export-data-type"
              value={exportDataType.value}
              items={exportDataItem}
              onSelect={item => handleExportDataType(item as ExportDataItemType)}
              className="w-50 mb-4"
            />
            <div className="my-2 fw-bold">ワークスペース選択</div>
            <div className="mb-2">他ワークスペースもまとめてエクスポートできます｡ワークスペースを選択してください｡</div>
            <ItemEdit
              items={items}
              selectedItems={selectedWorkspaces}
              label="ワークスペースを追加"
              itemName="ワークスペース"
              onChange={setSelectedWorkspaces}
            />
          </div>
          <div className="mb-4">
            <div className="my-2 fw-bold">エクスポート期間設定</div>
            <div className="mb-2">最大31日分エクスポートが可能です｡</div>
            <div className="d-flex">
              <Button outline onClick={() => setOpenRangeDatePicker(true)}>
                {period
                  ? `${dayjs(period.start).format('YYYY/MM/DD')} - ${dayjs(period.end).format('YYYY/MM/DD')}`
                  : '開始日と終了日を決定'}
              </Button>
              <SingleDateRangePicker
                isOpen={openRangeDatePicker}
                from={period && period.start}
                to={period && period.end}
                maxRange={DATE_PICKER_MAX_DAYS}
                maxDate={dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
                  .subtract(1, 'days')
                  .toDate()}
                onCancel={() => setOpenRangeDatePicker(false)}
                onChange={(start, end) => {
                  setPeriod({ start, end })
                  setOpenRangeDatePicker(false)
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="my-2 fw-bold">その他の設定</div>
            <div className="form-check">
              <Input
                id="include-productivity-value"
                className="form-check-input"
                checked={includeProductivityValue}
                type="checkbox"
                onChange={e => setIncludeProductivityValue(e.target.checked)}
              />
              <Label className="form-check-label" for="include-productivity-value">
                計画からの予測に生産性調整を含む
              </Label>
            </div>

            {exportDataType.key === ExportDataTypes.PlanDetail && (
              <div className="form-check">
                <Input
                  id="include-record"
                  className="form-check-input"
                  checked={includeRecord}
                  type="checkbox"
                  onChange={e => setIncludeRecord(e.target.checked)}
                />
                <Label className="check-form-label" for="include-record">
                  計画された作業時間内に記録された実績を含む
                </Label>
              </div>
            )}
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default CsvExportDialog
