import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/tenants'
import type { TenantApplicationSettingType, TenantData } from 'api/tenants'
import type { CreateResponse } from 'api/types'
import { UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE, makeErrorMessage } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type TenantState = API.TenantListResponse & {
  isRequesting: boolean
  errorMessage: string
  tenant?: TenantData
  tenantWithDate?: TenantData
  dataConnectionInfo: API.DataConnectionInfo[]
}

const initialState: TenantState = {
  isRequesting: false,
  errorMessage: '',
  partialTenants: [],
  tenant: undefined,
  tenantWithDate: undefined,
  dataConnectionInfo: [],
}

const formatTenantData = (action: PayloadAction<API.TenantResponse>) => {
  const startTime = action.payload.tenant.businessStartTime.split(':')
  const endTime = action.payload.tenant.businessEndTime.split(':')
  return {
    ...action.payload.tenant,
    businessEndTime:
      Number(endTime[0]) < Number(startTime[0]) ||
      (Number(endTime[0]) === Number(startTime[0]) && Number(endTime[1]) <= Number(startTime[1]))
        ? `${Number(endTime[0]) + 24}:${endTime[1]}`
        : action.payload.tenant.businessEndTime,
  }
}

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getTenantListSuccess: (state, action: PayloadAction<API.TenantListResponse>) => {
      state.isRequesting = false
      state.partialTenants = action.payload.partialTenants
    },
    getTenantSuccess: (state, action: PayloadAction<API.TenantResponse>) => {
      state.isRequesting = false
      state.tenant = formatTenantData(action)
    },
    getTenantWithDateSuccess: (state, action: PayloadAction<API.TenantResponse>) => {
      state.isRequesting = false
      state.tenantWithDate = formatTenantData(action)
    },
    getDataConnectionInfoSuccess: (state, action: PayloadAction<API.DataConnectionInfoResponse>) => {
      state.isRequesting = false
      state.dataConnectionInfo = action.payload.csvSettings
    },
    requestSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getTenantListSuccess,
  getTenantSuccess,
  getDataConnectionInfoSuccess,
  requestSuccess,
  getTenantWithDateSuccess,
} = tenantsSlice.actions

export const getTenantList = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getTenantList(commonParams(getState))
    .then((res: API.TenantListResponse) => dispatch(getTenantListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getTenant =
  (tenantId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getTenant(commonParams(getState), tenantId)
      .then((res: API.TenantResponse) => dispatch(getTenantSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getTenantWithDate =
  (date?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    const params = commonParams(getState)
    API.getTenant(params, params.tenantId, date)
      .then((res: API.TenantResponse) => dispatch(getTenantWithDateSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createTenant =
  (data: API.TenantEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createTenant(commonParams(getState), data)
      .then((res: CreateResponse) => {
        dispatch(requestSuccess())
        dispatch(getTenant(res.id))
      })

      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateTenant =
  (tenantId: number, data: API.TenantEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTenant(commonParams(getState), tenantId, data)
      .then(() => dispatch(requestSuccess()))
      .then(() => dispatch(getTenant(tenantId)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateTenantStatus =
  (tenantId: number, isActive: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTenantStatus(commonParams(getState), tenantId, isActive)
      .then(() => dispatch(requestSuccess()))
      .then(() => dispatch(getTenant(tenantId)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }
export const updateApplications =
  (tenantId: number, data: TenantApplicationSettingType[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTenantApplication(commonParams(getState), tenantId, data)
      .then(() => dispatch(requestSuccess()))
      .then(() => dispatch(getTenant(tenantId)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getDataConnectionInfo = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getDataConnectionInfo(commonParams(getState))
    .then((res: API.DataConnectionInfoResponse) => dispatch(getDataConnectionInfoSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const selectTenantsStatus = (state: RootState) => ({ ...state.tenants })

export default tenantsSlice.reducer
