import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Col, Card, CardBody } from 'reactstrap'

import { selectTenantsStatus } from 'slices/tenantsSlice'

import { VerticalTable } from 'components/common'
import type { VerticalItem } from 'components/common/types'

const TenantInformation: React.FC = () => {
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  const tenantItems: VerticalItem[] = React.useMemo(() => {
    return [
      { title: 'テナントID', data: tenant?.id },
      { title: '企業名', data: tenant?.name },
      { title: '事業所名', data: tenant?.salesOfficeName },
    ]
  }, [tenant])

  const officeItems: VerticalItem[] = React.useMemo(() => {
    const getTime = (time: string) => {
      const splitTime = time.split(':')
      return `${Number(splitTime[0])}:${splitTime[1]}`
    }

    return [
      { title: '郵便番号', data: tenant?.postalCode },
      { title: '都道府県', data: tenant?.prefecture },
      { title: '市区町村', data: tenant?.municipality },
      { title: 'それ以降の住所', data: tenant?.otherAddress },
      { title: '電話番号', data: tenant?.phoneNumber },
      {
        title: '営業時間',
        data: tenant ? getTime(tenant.businessStartTime) + ' 〜 ' + getTime(tenant.businessEndTime) : '-',
      },
    ]
  }, [tenant])

  const staffItems: VerticalItem[] = React.useMemo(() => {
    return [
      {
        title: '担当者',
        data: tenant?.personInChargeName,
      },
    ]
  }, [tenant?.personInChargeName])

  const stateItems: VerticalItem[] = React.useMemo(() => {
    return [
      {
        title: '登録日',
        data: tenant && moment(tenant?.createdAt).format('YYYY/MM/DD'),
      },
      {
        title: 'ご利用開始日',
        data: tenant?.startedAt ? moment(tenant?.startedAt).format('YYYY/MM/DD') : '-',
      },
      {
        title: 'ご利用停止日',
        data: !tenant?.isActive && tenant?.endedAt ? moment(tenant?.endedAt).format('YYYY/MM/DD') : '-',
      },
      {
        title: 'ステータス',
        data: tenant?.isActive ? '利用中' : '利用停止中',
      },
      { title: 'ご利用アカウント数', data: tenant?.userCount },
      { title: 'メンバー数', data: tenant?.workerCount },
    ]
  }, [tenant])

  return (
    <Card>
      <CardBody>
        <div className="fw-bold">テナント</div>
        <Col md={6} className="border-top border-end border-start my-2">
          <VerticalTable items={tenantItems} titleColumn={3} />
        </Col>

        <div className="fw-bold pt-3">事業所情報</div>
        <Col md={6} className="border-top border-end border-start my-2">
          <VerticalTable items={officeItems} titleColumn={3} />
        </Col>

        <div className="fw-bold pt-3">担当者情報</div>
        <Col md={6} className="border-top border-end border-start my-2">
          <VerticalTable items={staffItems} titleColumn={3} />
        </Col>

        <div className="fw-bold pt-3">ご利用状況</div>
        <Col md={6} className="border-top border-end border-start my-2">
          <VerticalTable items={stateItems} titleColumn={3} />
        </Col>
      </CardBody>
    </Card>
  )
}

export default TenantInformation
