import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CardBody, CardTitle, Label, Button } from 'reactstrap'

import type { PartialScheduleTypeData } from 'api/schedule_types'
import type { EditTemplateProps } from 'api/template'

import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import WorkPlanPopover from 'components/Schedules/WorkPlan/WorkPlanPopover'
import { InputFormat, TimeScale, ShiftBar } from 'components/common'
import { ColorTypes, ColumnSizes, UNSELECTED_SCHEDULE_TYPE_ID } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

type Props = {
  editData: EditTemplateProps
  setEditData: React.Dispatch<React.SetStateAction<EditTemplateProps>>
  setTemplateNameValidity: React.Dispatch<React.SetStateAction<boolean>>
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>
  disabledDeleteButton: boolean
}

const TemplateDetailView: React.FC<Props> = ({
  editData,
  setEditData,
  setTemplateNameValidity,
  setOpenDelete,
  disabledDeleteButton,
}) => {
  const params = useParams<'workspaceId'>()
  const workspaceId = React.useMemo(() => Number(params.workspaceId), [params.workspaceId])

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const dispatch = useDispatch()

  const { businessStartTime, businessDuration, getShiftBarXbyStartTime, businessStartIndex } = useBusinessTime()

  React.useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const templateNameValidation = React.useCallback((value: string | undefined): string => {
    if (typeof value === 'undefined') {
      return ''
    }
    if (value.length === 0 || value.length > 20) {
      return '1文字以上､20文字以下で入力してください'
    }
    return ''
  }, [])

  const updateEditTemplate = React.useCallback(
    (id: number | null, start: number, end: number, isSupport?: boolean) => {
      setEditData((prev: EditTemplateProps) => ({
        ...prev,
        ids: _.fill(prev.ids?.slice(), id === null ? null : { id, isSupport: !!isSupport }, start, end),
      }))
    },
    [setEditData]
  )

  const handleDeleteSchedule = React.useCallback(
    (startAt: number, duration: number) => updateEditTemplate(null, startAt, startAt + duration),
    [updateEditTemplate]
  )

  const handleSelectScheduleType = React.useCallback(
    (id: number, startAt: number, duration: number, isSupport: boolean) => {
      updateEditTemplate(id, startAt, startAt + duration, isSupport)
    },
    [updateEditTemplate]
  )

  const templateSchedules = React.useMemo(() => {
    return (
      editData.ids
        ?.reduce<{ count: number; id: number | null; startIndex: number; isSupport: boolean }[]>((acc, cur, index) => {
          const prev = _.last(acc)
          if (!prev || prev.id !== cur?.id || prev.isSupport !== cur.isSupport) {
            return acc.concat({ id: cur?.id ?? null, count: 1, startIndex: index, isSupport: !!cur?.isSupport })
          }
          acc.splice(acc.length - 1, 1, { ...prev, count: prev.count + 1 })
          return acc
        }, [])
        .filter(d => d.id !== null) || []
    )
  }, [editData])

  const handleShiftBarAdd = React.useCallback(
    (startPos: number, endPos: number) => {
      updateEditTemplate(UNSELECTED_SCHEDULE_TYPE_ID, startPos, endPos)
    },
    [updateEditTemplate]
  )

  const handleShiftBarChange = React.useCallback(
    (index: number, x: number, width: number) => {
      const target = templateSchedules.find((_val, i) => i === index)
      if (!target) {
        return
      }
      setEditData((prev: EditTemplateProps) => ({
        id: prev.id,
        ids: _.chain(prev.ids?.slice())
          .fill(null, target.startIndex, target.startIndex + target.count)
          .fill({ id: target.id!, isSupport: target.isSupport }, x, x + width)
          .valueOf(),

        name: prev.name,
      }))
    },
    [templateSchedules, setEditData]
  )

  const shiftBarItems = React.useMemo(() => {
    return templateSchedules.map((val, index) => {
      const target = (val.isSupport ? partialWorkspaces : partialScheduleTypes).find(st => st.id === val.id)
      const selected = {
        id: val.id!,
        name: target?.name ?? '',
        color: val.isSupport ? ColorTypes.Silver : (target as PartialScheduleTypeData)?.color ?? ColorTypes.Silver,
      }
      const startTime = moment()
        .startOf('day')
        .add((val.startIndex + businessStartIndex) * 15, 'minutes')
        .format()
      const x = getShiftBarXbyStartTime(startTime, dayjs().format('YYYY-MM-DD')) // 第２引数には現在日時を使用する

      return {
        id: `item-${index}`,
        content: (
          <WorkPlanPopover
            workspaceId={workspaceId}
            scheduleId={val.id!}
            selected={selected}
            viewWorkspace={true}
            startTime={startTime}
            duration={val.count * 900}
            disabled={false}
            onSelect={item => {
              const isSupport = !item?.color
              handleSelectScheduleType(item.id, x, val.count, isSupport)
            }}
            onDelete={() => handleDeleteSchedule(x, val.count)}
            isGroup={false}
          />
        ),
        x,
        width: val.count,
        color: (target as PartialScheduleTypeData)?.color ?? ColorTypes.Silver,
        disabled: false,
      }
    })
  }, [
    templateSchedules,
    partialWorkspaces,
    partialScheduleTypes,
    getShiftBarXbyStartTime,
    workspaceId,
    handleSelectScheduleType,
    handleDeleteSchedule,
    businessStartIndex,
  ])

  return (
    <div className="h-100 overflow-auto">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="font-large fw-bold">予定テンプレート情報</CardTitle>
          <span className="font-x-small text-muted">※必須項目</span>
        </div>
        <InputFormat
          label="名前※"
          placeholder="予定テンプレート名"
          value={editData.name}
          validations={[templateNameValidation]}
          maxLength={100}
          size={ColumnSizes.middle}
          onChange={value =>
            setEditData(prev => ({
              ...prev,
              name: value,
            }))
          }
          onValidate={setTemplateNameValidity}
        />

        <div className="d-flex mt-3">
          <Label md={ColumnSizes.short}>ID</Label>
          <div className="d-flex flex-column mx-3">
            <Label md={ColumnSizes.middle} className="font-middle">
              {editData.id || '未保存'}
            </Label>
            <Label className=" text-gray-dark font-small">
              シフトインポート時等に予定テンプレートを指定するために必要になります｡
            </Label>
          </div>
        </div>
      </CardBody>

      <CardBody className="pt-0">
        <div className="text-nowrap overflow-auto border-end">
          <TimeScale />
          <ShiftBar
            items={shiftBarItems}
            businessStartTime={businessStartTime}
            shiftBarWidth={businessDuration}
            onAdd={(startPos, endPos) => handleShiftBarAdd(startPos, endPos)}
            onChange={(idx, x, width) => handleShiftBarChange(idx, x, width)}
            disabled={_.isEmpty(partialScheduleTypes) || _.isEmpty(partialWorkspaces)}
          />
        </div>
      </CardBody>

      <CardBody>
        <CardTitle className="font-large fw-bold">予定テンプレートの削除</CardTitle>
        <Button
          outline
          color="danger"
          className="my-3"
          onClick={() => setOpenDelete(true)}
          disabled={disabledDeleteButton}
        >
          この予定テンプレートを削除
        </Button>
      </CardBody>
    </div>
  )
}
export default TemplateDetailView
