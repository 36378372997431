import dayjs from 'dayjs'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle } from 'reactstrap'

import type { WorkerCount } from 'api/workspaces'

import { selectDashboardStatus } from 'slices/dashboardSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { AssignedNumberTable } from 'components/common'
import type { TableItemType, ItemScheduleType } from 'components/common/types'

const getCounts = (data: WorkerCount[], date: string): Map<number, number> => {
  const counts: [number, number][] = data.map(d => {
    // 24:00以降(dateが日付が一致しない)場合はhourの値に24を足す
    const hour = dayjs(d.time).startOf('day').isSame(dayjs(date).startOf('day'), 'days')
      ? dayjs(d.time).hour()
      : dayjs(d.time).hour() + 24
    return [hour, d.workerCount]
  })
  return new Map<number, number>(counts)
}
const getWorkspaceCounts = (schedules: ItemScheduleType[]): Map<number, number> => {
  return schedules.reduce((acc, cur) => {
    Array.from(cur.counts.entries()).forEach(([key, value]) => acc.set(key, (acc.get(key) || 0) + value))
    return acc
  }, new Map<number, number>())
}

type Props = {
  close?: boolean
  total?: boolean
  date: string
}
const WorkerCountsCard: React.FC<Props> = props => {
  const { close, total, date } = props

  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)
  const { workerCounts } = useSelector(selectDashboardStatus, shallowEqual)

  const correctionBusinessTime = React.useMemo(() => {
    const round = tenantWithDate && moment(tenantWithDate.businessEndTime, 'HH:mm').minutes() > 0 ? 1 : 0
    const start = tenantWithDate ? `${tenantWithDate.businessStartTime.slice(0, 2)}:00` : '06:00'
    // 24:00 が 00:00 にならないように number で計算する
    const end = tenantWithDate ? `${Number(tenantWithDate.businessEndTime.slice(0, 2)) + round}:00` : '20:00'
    return { start, end }
  }, [tenantWithDate])

  const lastImportedAt = React.useMemo(() => dayjs().format('YYYY/MM/DD HH:mm:ss'), [])
  const items = React.useMemo((): TableItemType[] => {
    return workerCounts.map(workspace => {
      const schedules = workspace.planSummary.map(plan => {
        return {
          id: plan.scheduleTypeId,
          name: plan.scheduleTypeName,
          color: plan.scheduleTypeColor,
          counts: getCounts(plan.data, date),
        }
      })
      return {
        id: workspace.workspaceId,
        name: workspace.workspaceName,
        counts: getWorkspaceCounts(schedules),
        schedules,
      }
    })
  }, [date, workerCounts])

  return (
    <Card className="my-3">
      <CardBody>
        <CardTitle className="fw-bold">作業別配置人数一覧</CardTitle>

        <AssignedNumberTable
          businessStartTime={correctionBusinessTime.start}
          businessEndTime={correctionBusinessTime.end}
          items={items}
          close={close}
          total={total}
          tenantWithDate={tenantWithDate}
        ></AssignedNumberTable>
      </CardBody>

      <CardBody className="d-flex text-muted align-self-end pt-0">
        {lastImportedAt && <i className="icf-updated align-self-center pe-1" />}
        <small>{lastImportedAt}</small>
      </CardBody>
    </Card>
  )
}

export default WorkerCountsCard
