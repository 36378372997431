import dayjs from 'dayjs'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { Card, CardBody, DropdownItem } from 'reactstrap'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { DropdownList } from 'components/common'
import { UNSELECTED_SCHEDULE_TYPE_ID } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import { AssignToWorkTableContext } from './context'

import styles from './WorkPlanPopover.module.scss'

import type { PopupActions } from 'reactjs-popup/dist/types'

export type SelectItem = {
  id: number
  name?: string
  color?: string
}

type Props = {
  workspaceId: number
  scheduleId: number
  selected: SelectItem
  viewWorkspace: boolean
  startTime: string
  duration: number
  isGroup: boolean
  disabled: boolean
  onSelect: (item: SelectItem) => void
  onDelete: () => void
}

const WorkPlanPopover: React.FC<Props> = props => {
  const {
    workspaceId,
    scheduleId,
    selected,
    viewWorkspace,
    startTime,
    duration,
    isGroup,
    disabled,
    onSelect,
    onDelete,
  } = props

  const [dropdown, setDropdown] = React.useState(false)

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const { getTimeOver24h } = useBusinessTime()

  const time = React.useMemo(() => {
    const start = dayjs(startTime).local().format('HH:mm')
    const endTime = dayjs(startTime).local().add(duration, 'seconds').format('HH:mm')
    return `${getTimeOver24h(start, true)}〜${getTimeOver24h(endTime)}`
  }, [duration, getTimeOver24h, startTime])

  React.useEffect(() => {
    if (!isGroup && selected.id === UNSELECTED_SCHEDULE_TYPE_ID && scheduleId < 1) {
      ref.current?.open()
    }
  }, [isGroup, selected, scheduleId])

  const dropdownToggle = (
    <DropdownItem onClick={() => null} className="px-0 d-flex align-items-center">
      {selected?.color && (
        <div>
          <div className={`${styles.square} bg-${selected.color} me-1`}></div>
        </div>
      )}
      <span className="flex-grow-1 text-truncate">{selected?.name || '作業を選択'}</span>
      <i className="icf-carot_right font-large" />
    </DropdownItem>
  )

  const filteredWorkspaceList = React.useMemo(
    () =>
      partialWorkspaces
        .filter(w => w.id !== workspaceId && viewWorkspace)
        .filter(w => w.id !== selected.id || !!selected?.color),
    [selected?.color, selected.id, viewWorkspace, workspaceId, partialWorkspaces]
  )

  const filteredScheduleTypeList = React.useMemo(
    () => partialScheduleTypes.filter(s => s.id !== selected.id || !selected?.color),
    [partialScheduleTypes, selected?.color, selected.id]
  )

  const handleDelete = () => {
    onDelete()
    ref.current?.close()
  }

  const handleWorkspaceSelect = (id: number, name: string) => {
    onSelect({ id, name })
  }

  const ref = React.useRef<PopupActions>(null)
  const trigger = <div className="h-100 w-100">{selected?.name || '未選択'}</div>

  const { shiftKeyDown, selectedSchedules, setSelectedSchedules } = React.useContext(AssignToWorkTableContext)
  const handleOpen = () => {
    const found = selectedSchedules.find(s => s.scheduleId === scheduleId)
    if (shiftKeyDown) {
      if (found) {
        setSelectedSchedules(selectedSchedules.filter(s => s.scheduleId !== scheduleId))
      } else {
        setSelectedSchedules(selectedSchedules.concat([{ scheduleId, time }]))
      }
    } else {
      if (found && found.time !== time) {
        setSelectedSchedules(selectedSchedules.map(s => (s.scheduleId === scheduleId ? { scheduleId, time } : s)))
      } else {
        setSelectedSchedules([{ scheduleId, time }])
      }
    }
  }
  const handleClose = () => {
    if (selected.id === UNSELECTED_SCHEDULE_TYPE_ID) {
      onDelete()
    }
    if (shiftKeyDown) {
      if (selectedSchedules.slice(-1)[0]?.scheduleId !== scheduleId) {
        setSelectedSchedules(selectedSchedules.filter(s => s.scheduleId !== scheduleId))
      }
    } else {
      if (selectedSchedules.some(s => s.scheduleId === scheduleId && s.time !== time)) {
        setSelectedSchedules(selectedSchedules.map(s => (s.scheduleId === scheduleId ? { scheduleId, time } : s)))
      }
    }
  }

  return (
    <Popup
      ref={ref}
      trigger={trigger}
      onClose={handleClose}
      onOpen={handleOpen}
      position="left center"
      contentStyle={{ width: '12.5rem' }}
    >
      <Card>
        <CardBody className="p-2 font-x-small">
          <div className="d-flex justify-content-end font-large">
            {!disabled && <i className="icf-delete" onClick={() => handleDelete()} />}
            <i className="icf-close ps-1" onClick={() => ref.current?.close()} />
          </div>

          <DropdownList
            open={!disabled && dropdown}
            setOpen={() => setDropdown(!dropdown)}
            content={dropdownToggle}
            direction="end"
            dropdownClassName={styles.transform}
          >
            {filteredScheduleTypeList.map((schedule, index) => (
              <DropdownItem
                key={`schedule-${schedule.id}-${index}`}
                className={`d-flex font-x-small px-2 align-items-center ${styles.dropdownItems}`}
                onClick={() => onSelect({ id: schedule.id, name: schedule.name, color: schedule.color })}
              >
                <div>
                  <div className={`${styles.square} bg-${schedule.color} me-1`}></div>
                </div>
                <span className="text-truncate">{schedule.name}</span>
              </DropdownItem>
            ))}
            {filteredScheduleTypeList.length > 0 && filteredWorkspaceList.length > 0 && <DropdownItem divider />}
            {filteredWorkspaceList.map((workspace, index) => (
              <DropdownItem
                key={`workspace-${workspace.id}-${index}`}
                className={`d-flex font-x-small px-2 ${styles.dropdownItems}`}
                onClick={() => handleWorkspaceSelect(workspace.id, workspace.name)}
              >
                <span className="text-truncate">{workspace.name}</span>
              </DropdownItem>
            ))}
          </DropdownList>

          <div className="mb-1">{time}</div>
        </CardBody>
      </Card>
    </Popup>
  )
}

export default WorkPlanPopover
