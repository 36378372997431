import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import type { WorkersPlanData, WorkersPlan, UpdatePlanSchedule } from 'api/plans'
import type { TenantData } from 'api/tenants'

import { selectPlansStatus } from 'slices/plansSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import type { EditSchedule } from 'components/common/types'
import type { FitDuringShiftTimeData } from 'components/common/utils'
import { SHIFT_SCHEDULE_TYPE_ID, SUPPORT_SCHEDULE_TYPE_ID } from 'components/common/utils'

import useBusinessTime from './useBusinessTime'

export type WorkerEditSchedules = EditSchedule & {
  workerId: number
  shiftSchedules: EditSchedule[]
  supportShiftSchedules: EditSchedule[]
  workSchedules: EditSchedule[]
}

const usePlans = (tenantWithDate?: TenantData) => {
  const { plans, planList } = useSelector(selectPlansStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const { businessStartTime } = useBusinessTime(tenantWithDate)

  const {
    planWorkspaceId,
    planWorkDate,
    planScheduleTypes,
    planLastUpdatedAt,
    planLastUpdater,
    shiftLastUpdatedAt,
    shiftLastUpdater,
  } = React.useMemo(() => {
    if (!plans) {
      return {
        planWorkspaceId: 0,
        planWorkDate: '',
        planScheduleTypes: [],
        planLastUpdatedAt: '',
        planLastUpdater: '',
        shiftLastUpdatedAt: '',
        shiftLastUpdater: '',
      }
    }

    return {
      planWorkspaceId: plans.workspace.id,
      planWorkDate: plans.workDate,
      planScheduleTypes: plans.partialScheduleTypes,
      planLastUpdatedAt: plans.planUpdatedAt,
      planLastUpdater: plans.planUpdatedAtByName,
      shiftLastUpdatedAt: plans.workerShiftUpdatedAt,
      shiftLastUpdater: plans.workerShiftUpdatedAtByName,
    }
  }, [plans])

  const planStartDateTime = React.useMemo(() => {
    const startTime = businessStartTime.split(':')
    const startDateTime = new Date(planWorkDate)
    startDateTime.setHours(Number(startTime[0]))
    startDateTime.setMinutes(Number(startTime[1]))
    return startDateTime
  }, [businessStartTime, planWorkDate])

  const dailyTarget = React.useMemo(
    () => planList?.dailyPlans.find(daily => daily.workDate === planWorkDate),
    [planList, planWorkDate]
  )

  const workerEditSchedules = React.useMemo(() => {
    if (!plans) {
      return []
    }

    const workersPlan = plans.groups.flatMap(g => g.workersPlan.flatMap(w => ({ ...w, isSupported: g.isSupported })))
    return workersPlan.map(workerPlan => {
      const workShift = workerPlan.workShifts
      const workType = workerPlan.workScheduleTypes
      const shiftSchedules: EditSchedule[] = []
      const supportShiftSchedules: EditSchedule[] = []
      const workSchedules: EditSchedule[] = []

      if (workerPlan.isSupported) {
        // シフト用スケジュール(応援先)
        for (let i = 0; i < workShift.length; i += 3) {
          if (workShift[i] === planWorkspaceId) {
            const shiftValue = workShift[i]
            const startIndex = i
            const startAt = new Date(planStartDateTime.getTime())
            const elapsedMin = i * 5
            startAt.setMinutes(startAt.getMinutes() + elapsedMin)

            while (shiftValue === workShift[i + 3] && i + 3 < workShift.length) {
              if (i + 3 < workShift.length) {
                i += 3
              }
            }
            const endIndex = i + 2

            shiftSchedules.push({
              scheduleId: Math.random(),
              scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID,
              supportWorkspaceId: null,
              supportWorkspaceName: null,
              startAt: startAt.toISOString(),
              duration: (endIndex - startIndex + 1) * 300,
            })
          }
        }
      } else {
        // シフト用スケジュール
        for (let i = 0; i < workShift.length; i += 3) {
          if (workShift[i]) {
            const startIndex = i
            const startAt = new Date(planStartDateTime.getTime())
            const elapsedMin = i * 5
            startAt.setMinutes(startAt.getMinutes() + elapsedMin)

            while (workShift[i + 3] !== 0 && i + 3 < workShift.length) {
              if (i + 3 < workShift.length) {
                i += 3
              }
            }
            const endIndex = i + 2

            shiftSchedules.push({
              scheduleId: Math.random(),
              scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID,
              supportWorkspaceId: null,
              supportWorkspaceName: null,
              startAt: startAt.toISOString(),
              duration: (endIndex - startIndex + 1) * 300,
            })
          }
        }

        // 応援シフト(他ワークスペース)用スケジュール
        for (let i = 0; i < workShift.length; i += 3) {
          if (workShift[i] !== 0 && workShift[i] !== planWorkspaceId) {
            const shiftValue = workShift[i]
            const startIndex = i
            const startAt = new Date(planStartDateTime.getTime())
            const elapsedMin = i * 5
            startAt.setMinutes(startAt.getMinutes() + elapsedMin)

            while (shiftValue === workShift[i + 3] && i + 3 < workShift.length) {
              if (i + 3 < workShift.length) {
                i += 3
              }
            }
            const endIndex = i + 2

            const supportWorkspace = partialWorkspaces.find(w => w.id === workShift[i])
            const supportWorkspaceName = supportWorkspace?.name || null

            supportShiftSchedules.push({
              scheduleId: Math.random(),
              scheduleTypeId: SUPPORT_SCHEDULE_TYPE_ID,
              supportWorkspaceId: workShift[i],
              supportWorkspaceName: supportWorkspaceName,
              startAt: startAt.toISOString(),
              duration: (endIndex - startIndex + 1) * 300,
            })
          }
        }
      }

      // 作業用スケジュール
      for (let i = 0; i < workType.length; i += 3) {
        if (workType[i] !== 0 && workShift[i] === planWorkspaceId) {
          const typeValue = workType[i]
          const startIndex = i
          const startAt = new Date(planStartDateTime.getTime())
          const elapsedMin = i * 5
          startAt.setMinutes(startAt.getMinutes() + elapsedMin)

          while (typeValue === workType[i + 3] && i + 3 < workShift.length) {
            if (i + 3 < workType.length) {
              i += 3
            }
          }
          const endIndex = i + 2

          workSchedules.push({
            scheduleId: Math.random(),
            scheduleTypeId: typeValue,
            supportWorkspaceId: null,
            supportWorkspaceName: null,
            startAt: startAt.toISOString(),
            duration: (endIndex - startIndex + 1) * 300,
          })
        }
      }

      return { workerId: workerPlan.workerId, shiftSchedules, supportShiftSchedules, workSchedules }
    })
  }, [plans, planStartDateTime, planWorkspaceId, partialWorkspaces])

  const getEditSchedulesFromWorkPlan = React.useCallback(
    (workerPlan: WorkersPlanData, isSupport: boolean, isOnlyShift?: boolean): EditSchedule[] => {
      const scheduleData = workerEditSchedules.find(w => w.workerId === workerPlan.workerId)

      if (!scheduleData) {
        return []
      }

      if (isOnlyShift) {
        // シフトのみ使用(シフト管理画面)
        return scheduleData.shiftSchedules
      }

      // シフト+作業スケジュール
      const schedule = scheduleData.shiftSchedules.concat(scheduleData.workSchedules)

      if (isSupport) {
        // 応援先の場合は応援シフトスケジュールは含まない
        return schedule
      }

      // 応援シフトスケジュールを追加する
      return schedule.concat(scheduleData.supportShiftSchedules)
    },
    [workerEditSchedules]
  )

  const getWorkerPlanFromUpdatePlanSchedule = React.useCallback(
    (updateSchedule: UpdatePlanSchedule[]): WorkersPlan[] => {
      const targetUpdateSchedules = updateSchedule.filter(schedule => schedule.schedule?.scheduleTypeId)
      const workersPlan = targetUpdateSchedules.reduce((acc: WorkersPlan[], cur): WorkersPlan[] => {
        const workerId = cur.schedule?.workerId
        const startAt = cur.schedule!.startAt
        const duration = cur.schedule!.duration / 300 // 300秒(5分)区切りに計算
        const isSupport = cur.isSupport // 応援グループの判定

        const startDateTime = new Date(startAt)
        const diffTimeMin = (startDateTime.getTime() - planStartDateTime.getTime()) / (60 * 1000) // 差分を分単位にする
        const startIndex = diffTimeMin / 5 // 5分区切りに計算
        const endIndex = startIndex + duration
        const index = acc.findIndex(w => w.workerId === workerId)

        const originalWorkerPlan = plans!.groups.flatMap(g => g.workersPlan).find(w => w.workerId === workerId)

        const workerPlan =
          index >= 0
            ? acc[index]
            : {
                workerId: workerId!,
                workShifts: new Array<number>(288).fill(0),
                workScheduleTypes: new Array<number>(288).fill(0),
              }

        if (index < 0) {
          if (originalWorkerPlan) {
            for (let i = 0; i < originalWorkerPlan.workShifts.length; i++) {
              if (
                originalWorkerPlan.workShifts[i] &&
                originalWorkerPlan.workShifts[i] !== planWorkspaceId &&
                isSupport
              ) {
                workerPlan.workShifts[i] = originalWorkerPlan.workShifts[i]!
                workerPlan.workScheduleTypes[i] = originalWorkerPlan.workScheduleTypes[i]
              }
            }
          }
        }

        if (cur.schedule?.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID) {
          for (let i = startIndex; i < endIndex; i++) {
            if (workerPlan.workShifts[i] === 0) {
              workerPlan.workShifts[i] = planWorkspaceId
            }
          }
        } else if (cur.schedule?.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID) {
          for (let i = startIndex; i < endIndex; i++) {
            workerPlan.workShifts[i] = cur.schedule.supportWorkspaceId!
            if (originalWorkerPlan) {
              workerPlan.workScheduleTypes[i] = originalWorkerPlan.workScheduleTypes[i]
            }
          }
        } else {
          for (let i = startIndex; i < endIndex; i++) {
            if (cur.schedule?.scheduleTypeId) {
              workerPlan.workShifts[i] = planWorkspaceId
              workerPlan.workScheduleTypes[i] = cur.schedule.scheduleTypeId
            }
          }
        }

        if (index < 0) {
          acc.push(workerPlan)
        }

        return acc
      }, [])

      return workersPlan
    },
    [planStartDateTime, planWorkspaceId, plans]
  )

  const getWorkerPlanFromUpdatePlanScheduleForShift = React.useCallback(
    (updateSchedule: UpdatePlanSchedule[]): WorkersPlan[] => {
      const updateShiftSchedule = updateSchedule.filter(
        schedule => schedule.schedule?.scheduleTypeId && schedule.schedule?.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID
      )
      const workersPlan = updateShiftSchedule.reduce((acc: WorkersPlan[], cur): WorkersPlan[] => {
        const workerId = cur.schedule?.workerId
        const startAt = cur.schedule!.startAt
        const duration = cur.schedule!.duration / 300 // 300秒(5分)区切りに計算

        const startDateTime = new Date(startAt)
        const diffTimeMin = (startDateTime.getTime() - planStartDateTime.getTime()) / (60 * 1000) // 差分を分単位にする
        const startIndex = diffTimeMin / 5 // 5分区切りに計算
        const endIndex = startIndex + duration

        const index = acc.findIndex(w => w.workerId === workerId)
        if (index >= 0) {
          acc[index].workShifts = acc[index].workShifts.map((shift, i) => {
            if (i >= startIndex && i < endIndex) {
              return planWorkspaceId
            }
            return shift
          })
        } else {
          if (plans) {
            const workerPlan = plans!.groups.flatMap(g => g.workersPlan).find(w => w.workerId === workerId)
            const initWorkShifts = new Array<number>(288).fill(0)
            const newWorkShifts = initWorkShifts.map((shift, i) => {
              if (i >= startIndex && i < endIndex) {
                return planWorkspaceId
              }
              return shift
            })

            acc.push({
              workerId: workerId!,
              workShifts: newWorkShifts,
              workScheduleTypes:
                workerPlan && workerPlan.workScheduleTypes.length === 288
                  ? workerPlan.workScheduleTypes
                  : new Array<number>(288).fill(0),
            })
          }
        }

        return acc
      }, [])

      return workersPlan
    },
    [planStartDateTime, planWorkspaceId, plans]
  )

  const getFitDuringShiftTimeData = React.useCallback((): FitDuringShiftTimeData[] => {
    if (!plans) {
      return []
    }
    const workersPlan = plans?.groups.flatMap(g =>
      g.workersPlan.flatMap(w => ({ workerId: w.workerId, workShift: w.workShifts, isSupport: g.isSupported }))
    )

    const shiftData: FitDuringShiftTimeData[] = []

    const shiftCheck = (shiftValue: number, workShiftValue: number | null, isSupport: boolean) => {
      return isSupport ? shiftValue === workShiftValue : shiftValue !== 0
    }

    for (const worker of workersPlan) {
      const workShift = worker.workShift
      for (let i = 0; i < workShift.length; i += 3) {
        if (workShift[i]) {
          const shiftValue = workShift[i]!
          const startIndex = i
          const startAt = new Date(planStartDateTime.getTime())
          const elapsedMin = i * 5
          startAt.setMinutes(startAt.getMinutes() + elapsedMin)

          while (shiftCheck(shiftValue, workShift[i + 3], worker.isSupport) && i + 3 < workShift.length) {
            if (i + 3 < workShift.length) {
              i += 3
            }
          }
          const endIndex = i + 2

          shiftData.push({
            workerId: worker.workerId,
            startAt: startAt.toISOString(),
            duration: (endIndex - startIndex + 1) * 300,
          })
        }
      }
    }

    return shiftData
  }, [planStartDateTime, plans])

  const hasWorkersWithShifts = React.useMemo(
    () =>
      plans && plans?.groups.some(group => group.workersPlan.some(worker => worker.workShifts.some(shift => shift))),
    [plans]
  )

  const getEmptyShiftData = React.useCallback(
    (workerId: number): UpdatePlanSchedule[] => [
      {
        scheduleId: null,
        schedule: {
          scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID,
          supportWorkspaceId: null,
          startAt: new Date(planStartDateTime.getTime()).toISOString(),
          duration: 0,
          workerId: workerId,
          groupId: null,
        },
      },
    ],
    [planStartDateTime]
  )

  return {
    planStartDateTime,
    planWorkspaceId,
    planWorkDate,
    planScheduleTypes,
    planLastUpdatedAt,
    planLastUpdater,
    shiftLastUpdatedAt,
    shiftLastUpdater,
    dailyTarget,
    getEditSchedulesFromWorkPlan,
    getWorkerPlanFromUpdatePlanSchedule,
    getWorkerPlanFromUpdatePlanScheduleForShift,
    getFitDuringShiftTimeData,
    hasWorkersWithShifts,
    getEmptyShiftData,
  }
}

export default usePlans
